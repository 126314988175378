var generic = generic || {};
var site = site || {};

(function ($) {
  Drupal.behaviors.emailSignupBlockV1 = {
    templateContainer: $('.js-email-signup-block-v1'),
    collateMessages: function (messages, pcEmailPromotionError, pcEmailAddressError) {
      if (Array.isArray(messages)) {
        var messageArray = [];

        for (var i = 0; i < messages.length; i++) {
          if (
            messages[i].key === 'required.pc_email_promotions.email_signup' &&
            pcEmailPromotionError
          ) {
            messageArray.push(pcEmailPromotionError);
          } else if (
            messages[i].key === 'required.pc_email_address.email_signup' &&
            pcEmailAddressError
          ) {
            messageArray.push(pcEmailAddressError);
          } else {
            messageArray.push(messages[i].text);
          }
        }

        return messageArray.join('<br />');
      }

      return;
    },
    initEmailSignupBlock: function (context) {
      var self = this;

      // hide the newsletter signup in the footer if they are opted into email promtions
      // but we need to wait until the user data is loaded which is done after all the data is loaded
      $(document).on('user.loaded', function () {
        var user = generic.user.getUser();

        if (user.pc_email_optin === 1) {
          $('.js-email-signup-block-inner', context).hide();
        }
      });

      var emailContainerNode = self.templateContainer.find('.js-email-signup-block-inner');

      if (!emailContainerNode) {
        return null;
      }

      var emailFormNode = emailContainerNode.find('form'),
        emailSuccessNode = emailContainerNode.find('.js-email-signup-block__success', context),
        emailErrorNode = emailContainerNode.find('.js-email-signup-block__error', context),
        emailContentNode = emailContainerNode.find('.email-signup-block__form-content', context),
        pcEmailPromotionError = emailContainerNode
          .find('input[name ="error_required_pc_email_promotion"]')
          .val(),
        pcEmailAddressError = emailContainerNode
          .find('input[name ="error_required_pc_email_address"]')
          .val(),
        pcEmailPromotionPresent = emailContainerNode
          .find('input[name ="PC_EMAIL_PROMOTIONS_PRESENT"]')
          .val();

      emailFormNode.once('email_signup').on('submit', function (submitEvt) {
        submitEvt.preventDefault();

        emailSuccessNode.addClass('hidden');
        emailErrorNode.addClass('hidden');
        emailContentNode.removeClass('hidden');

        var pcEmailAddress = emailContainerNode.find('input[name ="PC_EMAIL_ADDRESS"]').val(),
          pcEmailPromotionsChecked = $("#email-signup input[name='PC_EMAIL_PROMOTIONS']").prop(
            'checked'
          );
        // set the ACTION for the email signup based on the whether the PC_EMAIL_PROMOTIONS is checked
        // its not required to sign up so we dont want it set by default
        var action = pcEmailPromotionsChecked ? 'set' : 'unset';

        $("#email-signup input[name='ACTION']").val(action);

        var preValidationErrors = [],
          obj;

        if (pcEmailAddress === '' && pcEmailAddressError) {
          obj = {};
          obj['key'] = 'required.pc_email_address.email_signup';
          preValidationErrors.push(obj);
        }
        if (pcEmailPromotionPresent === '1') {
          if (!pcEmailPromotionsChecked && pcEmailPromotionError) {
            obj = {};
            obj['key'] = 'required.pc_email_promotions.email_signup';
            preValidationErrors.push(obj);
          }
        }

        var messages = [];

        if (preValidationErrors.length > 0) {
          messages = self.collateMessages(
            preValidationErrors,
            pcEmailPromotionError,
            pcEmailAddressError
          );
        }
        if (messages.length > 0) {
          emailErrorNode.empty();
          emailFormNode.addClass('error');
          emailErrorNode.html(messages).removeClass('hidden');
        } else {
          // we want to get all the fields in code because 'serialize' encodes potentially bad emails and decode doesn't decode '+' as spaces
          var fields = [
            'PC_EMAIL_ADDRESS',
            'PC_EMAIL_PROMOTIONS',
            'PC_EMAIL_PROMOTIONS_PRESENT',
            'ACTION',
            'LAST_SOURCE',
            'ORIGINAL_SOURCE',
            'LANGUAGE_ID',
            'COUNTRY_ID',
            'redirect_or_text',
            'redirect'
          ];
          var acceptedCommercialfield = $(
            "#email-signup input[name='ACCEPTED_COMMERCIAL']",
            context
          );

          if (acceptedCommercialfield) {
            fields.push('ACCEPTED_COMMERCIAL');
          }

          var paramObj = {};

          // loop through all the fields and get the values
          $.each(fields, function (index, value) {
            var formField = $('#email-signup input[name=' + value + ']', context);

            // for the unchecked checkboxes we want to send empty strings to backend for processing
            if (formField.is(':checkbox') && !formField.prop('checked')) {
              paramObj[value] = '';
            } else {
              paramObj[value] = formField.val();
            }
          });
          paramObj['_SUBMIT'] = 'email_signup';

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [paramObj],
            onSuccess: function () {
              emailContentNode.addClass('hidden');
              emailSuccessNode.removeClass('hidden');
            },
            onFailure: function (jsonRpcResponse) {
              messages = self.collateMessages(
                jsonRpcResponse.getMessages(),
                pcEmailPromotionError,
                pcEmailAddressError
              );
              emailErrorNode.empty();
              emailFormNode.addClass('error');
              emailErrorNode.html(messages).removeClass('hidden');
            }
          });
        }
      });
    },

    attach: function (context) {
      var self = this;

      self.initEmailSignupBlock(context);
    }
  };
})(jQuery);
